import { createGlobalStyle } from "styled-components";


const GlobelStyleCss = createGlobalStyle`
*{
	margin:0;
	padding:0;
}

body{
	margin:0;
	padding:0;
    border:none;
	font-family: 'Helvetica';
}
.container {
    max-width: 1372px;
    margin: 0px auto;
    width: 100%;
}
a , a:hover{
	text-decoration: none;
}
.btn.focus, .btn:focus {
    outline: 0;
    box-shadow:none;
}
a.btn.btn-get-stareted:active {
    border: none;
}
p {
    font-family: Helvetica Neue LT Std;
    font-size: 16px;
    color: #555555;
}
h2 {
    font-family: 'Playfair Display', serif;
    font-size: 60px;
	font-weight:bold;
	color: #39332E;
}
h3 {
    font-size: 24px;
    color: #555555;
    line-height: 36px;
}
ul {
    padding-left: 0;
}
h4 , h6{
font-family:Helvetica Neue LT Std;
}
.community-form fieldset.MuiOutlinedInput-notchedOutline.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
    border: none;
}

.checkout-main .checkout_heading_address {
    font-size: 35px !important;
    font-weight: 600 !important;
    color: #000 !important;
    font-family: "Playfair Display", serif !important;
    padding: 0 !important;
    border: none !important;
    margin-bottom: 20px !important;
}

body .checkout-main .express-shipping.ship-address {
    justify-content: flex-start;
    font-size: 24px;
    font-weight: 400;
    color: #707070;
    gap: 0;
    margin-bottom: 30px;
}

.checkout-main .express-shipping.ship-address input {
    top: -3px;
    margin-right: 10px;
    height: 24px;
    width: 24px;
}

.shipping-form {
    margin: 10px 0px;
}



/********* Today order css */
.express-shipping {
    font-size: 18px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;

    input[type="checkbox"] {
        width: 18px;
        height: 18px;
    }
}

.btn.btn-show {
        background: #2A533F 0% 0% no-repeat padding-box;
        border-radius: 28px;
        color: #fff;
        max-width: 178px;
        width: 100%;
        height: 39px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 18px;
        font-weight: bold;
        border:2px solid transparent;

    }
    .btn.btn-show:hover {
    border: 2px solid #2A533F;
    background: transparent;
    color:#2A533F !important;
}
@font-face {
    font-family: 'Helvetica';
    src: url('/fonts/Helvetica.eot');
    src: url('/fonts/Helvetica.eot?#iefix') format('embedded-opentype'),
        url('/fonts/Helvetica.woff2') format('woff2'),
        url('/fonts/Helvetica.woff') format('woff'),
        url('/fonts/Helvetica.ttf') format('truetype'),
        url('/fonts/Helvetica.svg#Helvetica') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Neue LT Std';
    src: url('/fonts/HelveticaNeueLTStd-Roman.eot');
    src: url('/fonts/HelveticaNeueLTStd-Roman.eot?#iefix') format('embedded-opentype'),
        url('/fonts/HelveticaNeueLTStd-Roman.woff2') format('woff2'),
        url('/fonts/HelveticaNeueLTStd-Roman.woff') format('woff'),
        url('/fonts/HelveticaNeueLTStd-Roman.ttf') format('truetype'),
        url('/fonts/HelveticaNeueLTStd-Roman.svg#HelveticaNeueLTStd-Roman') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Helvetica Neue LT Std';
    src: url('/fonts/HelveticaNeueLTStd-Bd.eot');
    src: url('/fonts/HelveticaNeueLTStd-Bd.eot?#iefix') format('embedded-opentype'),
        url('/fonts/HelveticaNeueLTStd-Bd.woff2') format('woff2'),
        url('/fonts/HelveticaNeueLTStd-Bd.woff') format('woff'),
        url('/fonts/HelveticaNeueLTStd-Bd.ttf') format('truetype'),
        url('/fonts/HelveticaNeueLTStd-Bd.svg#HelveticaNeueLTStd-Bd') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}



.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 450px;
  object-fit: contain;
}
.MuiInputBase-root.MuiFilledInput-root:before {
    border-bottom: none;

}
.MuiInputBase-root.MuiFilledInput-root:after {
    border-bottom: 2px solid rgb(42, 83, 63);
}
.MuiInputBase-root.MuiFilledInput-root:hover:not(.Mui-disabled, .Mui-error):before {
    border-bottom: none;
}
input {
    background: #fff !important;
}
.main_pagination_section_class .order_pagination ul.pagination li.active {
    color:  rgb(42, 83, 63)!important;
}
.btn-check:checked+.btn, .btn.active, .btn.show, .btn:first-child:active, :not(.btn-check)+.btn:active{
background-color:transparent !important;
border-color:transparent !important;
}
@media  (max-width:1500px){
    .container {
    max-width: 1280px;
}
}

@media (max-width:1439px){
.container {
    max-width: 1170px;
}
h2 {
    font-size: 45px;
}
}

@media (max-width:1280px){
    .container {
        max-width: 1050px;
    }
}
@media (max-width:1199px){
.container {
    max-width: 100%;
    padding: 0 30px;
}
}
@media (max-width:767px){
    .container {
        padding: 0 20px;
    }
    .MuiPaper-root.MuiDialog-paper.deleteAsking_popup {
        max-width: 90% !important;
        width: 100%;
    }

    h2 {
        
        font-size: 26px;
    }
}
@media (max-width:568px){
    h2 {
    
        font-size: 22px;
}
h3 {
    font-size: 18px;
    line-height: 24px !important;
}
.btn.btn-show {font-size:16px;}
.best-seller-section .item p {
    font-size:16px;
}
}
.MuiDrawer-paper {
    /* width: 100% !important;
    max-width: 500px !important; */
}
.form-control:focus {
    color: #212529;
    background-color: #fff;
    border-color:none;
    outline: 0;
    box-shadow: none
}
input#quantity {
    font-size: 19px;
    /* margin-top: -3px; */
    height: 100%;
    background: transparent !important;
    color: #707070;
}
.quality-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    i {
        color: #707070;
    }
    input {
        max-width: 63px;
        text-align: center;
        border: none;
        width: 100%;
    }
    .input-group {
        border: 1px solid rgb(57, 51, 46);
        max-width: 153px;
        border-radius: 28px;
        margin: 30px 0px 0px;
        padding: 0px 8px;
        width: 100%;
        height: 39px
    }
    button {
        background: transparent;
        color: #000;
        border: none;
        font-weight: 300;
        &:hover, &:focus {
            background: transparent;
            border: none;
                box-shadow: none;
        }
    }
    /* button:hover, button:focus {
        background: transparent;
        border: none;
            box-shadow: none;
    } */
}
.mini-root-div {
  position: fixed;
  right: 0px;
  top: 50%;
  transform: translateY(-50%);
  background: linear-gradient(90deg, rgb(42, 83, 63) 0%, rgb(233, 79, 36) 100%);
  padding: 13px;
  border-radius: 5px 0 0 5px;
  cursor: pointer;
 width : 60px;

    .fa.fa-shopping-cart {
        color: #fff;
        font-size: 24px;
    }
    span {
        position: absolute;
        top: 8px;
        right: 4px;
        background: #f00;
        color: #fff;
        font-size: 14px;
        width: 17px;
        height: 17px;
        border-radius: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        right : 15px;
    }
}
    fieldset {
    border-color: transparent !important;
}

button.toggle {
    display: none;
}
.MuiPaper-root.MuiPaper-elevation.MuiPaper-elevation16.MuiDrawer-paper.flayout_cart.MuiDrawer-paperAnchorRight {
    max-width: 662px;
    width: 100%;
}
.MuiPaper-root.MuiPaper-elevation.MuiPaper-elevation16.MuiDrawer-paper.flayout_cart.MuiDrawer-paperAnchorRight .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl {
    border: 1px solid #ddd;
}

.MuiPaper-root.addProduts {
    max-width: 70%;
    min-width: 70%;
}
.dialog_title_for_all > h2 {
    color: #fff;
}

@media(max-width:1199px){
span.number-cart {
    font-size: 12px !important;
}
}
@media(max-width:991px){
    button.toggle {
    display: block;
    background: transparent;
    border: none;
    color: #000;
}
.nav-collapse.collapse.hide {
    display: none !important;
}
button.toggle svg {
    height: 30px !important;
    width: 30px !important;
    filter: invert(1);
}
.nav-collapse.collapse.open_collapse_nav {
    flex-wrap: wrap;
}
.nav-collapse.collapse.open_collapse_nav {
    background:rgb(42 83 63);
    width: calc(100vw - 0px);
    position: absolute;
    left: -18px;
    transform: translateX(0px);
    top: 93px;
}
.nav-collapse.collapse.open_collapse_nav ul {
    max-width: 100%;
    flex: 0 0 100%;
}
.nav-collapse.collapse.open_collapse_nav ul {
    width: 100%;
    flex-wrap: wrap;
}
.nav-collapse.collapse.open_collapse_nav ul li {
    max-width: 100%;
    flex: 0 0 100%;
    padding: 20px 20px 0 !important;
}
.header-section .nav-collapse.collapse.open_collapse_nav ul li a span {
    font-size:16px;
    text-transform: uppercase;
}
.header-section.homePageHeader ul li a.active span {
    border-bottom: none !important;
}
.header-section ul.nav i {
    float: right;
    top: 0;
    position: relative;
    padding-left: 0px !important;
    font-size: 20px !important;
}
 .cart-icon {
    max-width: 26px !important;
    margin-left: 27px !important;
}
ul.header-icon {
    padding-bottom: 29px;
}
.header-section .nav-collapse.collapse {
    animation-name: shutter-in-left;
    animation-duration: 0.5s;
    animation-timing-function: linear;
    animation-delay: 0s;
    animation-iteration-count: 1;
    animation-direction: normal;
    animation-fill-mode: none;
}
.header-section .nav-collapse.collapse li:hover a i {
    transform: rotate(0deg) !important;
}
.nav-collapse.collapse.open_collapse_nav li.active-drop ul {
    display: block !important;
}
.nav-collapse.collapse.open_collapse_nav ul li ul {
    display: none !important;
}
.nav-collapse.collapse.open_collapse_nav li.active-drop a span i {
    transform: rotate(180deg) !important;
}
span.btn.btn-login {

    left: 0 !important;

}

.best-seller-section {
    margin-top: -40px;
    margin-bottom: 14px;
}
}


@media(max-width:767px){
.nav-collapse.collapse.open_collapse_nav {
    left: -9px;

}
.MuiPaper-root.MuiPaper-elevation.MuiPaper-elevation16.MuiDrawer-paper.flayout_cart.MuiDrawer-paperAnchorRight {
    max-width: 462px;
}

}

@media(max-width:568px){
    .nav-collapse.collapse.open_collapse_nav {
    left: -8px;

}
.nav-collapse.collapse.open_collapse_nav {
    top: 60px;
}
.MuiPaper-root.MuiPaper-elevation.MuiPaper-elevation16.MuiDrawer-paper.flayout_cart.MuiDrawer-paperAnchorRight {
    max-width: 340px;
}
}


.flexbox {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  position: fixed;
  z-index: 999;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100vw;
  height: 100vh;
  left: 50%;
  justify-content: center;
}

.flexbox>div {
  width: 300px;
  height: 300px;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  /* border: 1px solid rgba(255, 255, 255, 0.1); */
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: auto;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  overflow: hidden;
}

.bt-spinner {
  width: 75px;
  height: 75px;
  border-radius: 50%;
  background-color: transparent;
  border: 4px solid #f5f5f5;
  border-top-color: var(--green);
  -webkit-animation: 1s spin linear infinite;
  animation: 1s spin linear infinite;
}


.flexbox:before {
  content: "";
  width: 100%;
  height: 100vh;
  background: rgb(255 255 255 / 50%);
  backdrop-filter: blur(5px);
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

body.bg-salmon [class*="NavBarStyle-sc"] {
  visibility: hidden;
  opacity: 0;
}

/* 
.bg-salmon .fotter_section {
  display: none;
} */


@keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

p.error{
    margin-top: 15px;
    text-align: center !important;    
    color: red;
} 

    p.select-shipping-popup {
        font-size: 26px;
        font-weight: 700;
        margin-bottom: 4px;
    }

    p.select-shipping-address {
        display: flex;
        justify-content: center;
        font-size: 18px;
        margin-bottom: 12px;
}
p.suggested-address1 {
    display: flex;
    justify-content: center;
    font-size: 27px;
    margin-bottom: 5px;
}
p.address1-format {
    display: flex;
    justify-content: center;
}
p.address2-format {
    display: flex;
    justify-content: center;
    line-height:0px;
}
p.address3-format {
    display: flex;
    justify-content: center;
}
.isDistributer_main_dialog div#alert-dialog-title h2.MuiTypography-root.MuiTypography-h6 {
    color: rgb(255, 255, 255);
    text-align: left;
    text-transform: uppercase;
    font-family: "Helvetica Neue LT Std";
    padding: 6px 10px;
}
.MuiPaper-root.MuiDialog-paper.isDistributer {
    overflow-y: initial;
}
.isDistributer_main_dialog svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-i4bv87-MuiSvgIcon-root {
    position: absolute;
    /* filter: invert(1) !important; */
    opacity: 1 !important;
    top: 0px;
    right: 0 !important;
}
button.MuiButtonBase-root.MuiIconButton-root.close_button {
    position: absolute;
    right: 3px;
    top: 4px;
    background: rgb(255, 255, 255);
    z-index: 9999;
}
div#alert-dialog-title {
    background: #10281c;
}
.isDistributer_main_dialog div#alert-dialog-title {
    padding: 0;
    background: #102219;
}
.MuiPaper-root.MuiDialog-paper.isDistributer {
    max-width: 650px;
    width: 100%;
}
.MuiDialogContent-root.container_for_load_data p {
    text-align: center;
    /* padding: 10px 0; */
}
.MuiDialogContent-root.container_for_load_data a {
    color: #102219;
    text-transform: capitalize;
    font-weight: 600;
}

.resent_text_section{
    .resent_otp_again {
        background: none;
        border-top: none;
        border-left: none;
        border-right: none;
        color: #000;
        border-bottom: 2px solid #275441;

        &:hover {
            color:#275441;
        }
    }
    p{
        margin: 10px 0 0 0;
        color: #285440;
    }
}

/* address dialog */
.addessForm_main_dialog  {
    .MuiDialogTitle-root {

        button.close_button  {
            top: 8px;
        }
    }
    .MuiDialogContent-root {
        form {
            .MuiInputBase-formContro {
                &.Mui-disabled {
                    border-bottom-style: solid;
                }
            }
            .row-form {
                display: flex;
                gap: 20px;
                margin-top: 1rem;

                @media only screen and (max-width: 767px) {
                    flex-wrap: wrap;
                    gap: 0;
                    margin: 0;
                }

                .select {
                    margin-bottom: .5rem;
                    width: calc(100%/2 - 10px);

                    @media only screen and (max-width: 767px) {
                        width: 100%;
                    }
                }
                select {
                    width: 100%;
                    padding: 13px 15px 10px;
                    border: 1px solid rgb(196, 196, 196);
                    border-radius: 5px;
                    font-size: 16px;
                    background-color: transparent;
                    line-height: 1.2;

                    &:focus {
                        box-shadow: none;
                        outline: none;
                    }
                }
            }
            .field-class {
                .MuiFormControl-root {
                    .MuiFormHelperText-root {
                        text-align: left;
                    }
                }
                .MuiInputBase-root {
                    &.MuiFilledInput-root::before {
                        border-bottom: 1px solid rgba(0, 0, 0, 0.42);
                    }
                }
                .MuiInputBase-root {
                    /* border-bottom: 2px solid rgb(42, 83, 63); */
                    background: red;

                    .MuiFilledInput-root {

                        &::before {
                            border-bottom: 1px solid rgba(0, 0, 0, 0.42);
                        }                   
                    }

                }
                .MuiInputLabel-root {
                    &.Mui-focused {
                        color: rgba(0, 0, 0, 0.6);
                    }
                }
            }
        }
    }
}

.address_data_main_dialog {
    div#alert-dialog-title {
        min-height: 56px;
    }
    .MuiDialogContent-root {
        .select-shipping-popup, .suggested-address1 {
            @media only screen and (max-width: 767px) {
                font-size: 22px;
                line-height: 1.2;
            }
        }
        .select-shipping-address {
            @media only screen and (max-width: 767px) {
                font-size: 16px;
                line-height: 1.2;
            }
        }
        .content-address {
            p {
                @media only screen and (max-width: 767px) {
                    font-size: 14px;
                    line-height: 1.2;
                    margin-bottom: 0px;

                    &:last-child {
                        margin-bottom: 16px;
                    }
                }   
            }
        }
    }
}

body .MuiDialog-paper {
    margin: 0 10px;
}

.tooltipText {
   p {
    font-size: 14px;
    color: #fff;
    margin: 3px 0px 0px;
   }
}
.coutry-display-popup_main_dialog {

    .country-main-popup {
        display: flex;
        padding: 50px 0px 50px 0px;
    }
    .righ-side-content {
        padding-left: 10px;
    }
    a {
        text-decoration: underline;
    }
    
    button.country-navi-button {
        /* padding-top: 100px; */
        margin-top: 100px;
        background: red;
        color: white;
        border-radius: 4px;
        font-weight: bold;
        border:none;
    }
    
    h2#alert-dialog-title {
        border-bottom: none !important;
        justify-content: right;
    }

    div#alert-dialog-title {
        /* background: rgb(16, 40, 28); */
        background: white;
    }

    .righ-side-content h1 {
        font-size: 1.5rem;
    }
    .MuiDialogContent-root.container_for_load_data p{
        text-align: left;
    }
    }
    .Mui-disabled:before {
        border-bottom: unset;
    }
    .main_layout_section .us_store .linear-form .MuiStepper-root.MuiStepper-horizontal .MuiStepConnector-root.MuiStepConnector-horizontal {
        top: 38px;
        left: calc(-50% + 19px);
        transform: unset;
        width: unset;
        max-width: unset;
        right: calc(50% + 19px);
    }
    
    .MuiDialog-root {
        .dialog_scrollClass {
            padding: 0 10px;
            .switchStore {
                width: 100%;
                margin: 0;
                border-radius: 15px;

                .switchStore_main_dialog {
                    .MuiDialogTitle-root {
                        padding: 0;

                        .MuiButtonBase-root {
                            padding: 0;
                            right: 5px;
                            top: 5px;
                        }
                    }

                    .MuiDialogContent-root {
                        text-align: center;
                        padding: 30px;

                        a.switch-store {
                            background-color: #2a533f;
                            border: 2px solid #2a533f;
                            border-radius: 30px;
                            color: #fff;
                            padding: 10px 25px;
                            font-size: 24px;
                            line-height: 1.3;
                            display: inline-block;
                            transition: all ease-in-out 0.3s;

                            &:hover {
                                background-color: transparent;
                                color: #2a533f;
                            }
                        }
                    }
                }
            }
        }
    }





    .country_askingPopup_main_dialog {
        .action_button {
            button {
                background: 0% 0% no-repeat padding-box padding-box #39332e;
                border-radius: 50px;
                font-size: 18px;
                max-width: 100px;
                height: auto;
                display: flex;
                -webkit-box-align: center;
                align-items: center;
                -webkit-box-pack: center;
                justify-content: center;
                width: 100%;
                font-weight: bold;
                border: 2px solid #39332e;
                padding: 5px 20px;
                color: #fff;
                transition: all ease-in-out 0.3s;
    
                &:hover {
                    color:  #39332e;
                    background: transparent;
                }
            }
        }
    }

    .link_button {
    max-width: max-content;
    background: 0% 0% no-repeat padding-box padding-box rgb(42, 83, 63);
    border-radius: 28px;
    height: 39px;
    width: 100%;
    color: rgb(244, 240, 235);
    font-size: 18px;
    font-weight: bold;
    display: flex;
    flex-wrap: nowrap;
    -webkit-box-align: center;
    align-items: center;
    justify-content: center;
    border: 1px solid transparent;
    padding: 7px 50px 6px;
}
.link_button {
    margin: 30px 0 0;
}
.btn-link_button {
    background: transparent;
    border: 1px solid #2A533F;
    color: #2A533F;
}

.link_button:hover {
    background: transparent;
    border: 1px solid #2A533F;
    color: #2A533F;
}

`;

export default GlobelStyleCss;