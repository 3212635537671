import React from 'react'
import { storeAssigned } from '../../../services/Methods/normalMethods';
import { FooterStyleCss } from './components/Footer.style';
import { Tooltip } from 'react-tooltip';

export const Footer = (props) => {

    return (
        <FooterStyleCss>
            <div className="footer">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="footer-logo">
                                <div className="logo-2">
                                    {/* <a id="my-anchor-element"><img src="/images/patent.png" /></a>
                                    <Tooltip
                                        anchorSelect="#my-anchor-element"
                                        content="Unitred States Patent And Trademark Office Department of commerce"
                                    /> */}

                                    <ul>
                                        {/* <li>
                                        <a id="my-anchor-element"><img src="/images/patent.png"/></a>
                                        <Tooltip
                                            anchorSelect="#my-anchor-element"
                                            content="Unitred States Patent And Trademark Office Department of commerce"
                                        />
                                        </li> */}
                                        <li>
                                            {/* <a id="my-anchor-element2">  */}
                                            <img src="/images/C_T_Alle_Awards_logo.jpg"
                                                // style={{maxWidth:'190px'}} 
                                                className='C_T_Alle_Awards_logo'
                                            />
                                            {/* </a> */}
                                            {/* <Tooltip
                                                anchorSelect="#my-anchor-element2"
                                                content="Elle Magazine"
                                            /> */}
                                        </li>
                                    </ul>
                                </div>
                                <div className="logo-2">
                                    <h4>Featured</h4>
                                    <ul>
                                        <li>
                                            <a id="my-anchor-element1"><img src="/images/cosmo-logo.png" /></a>
                                            <Tooltip
                                                anchorSelect="#my-anchor-element1"
                                                content="Cosmo Trends"
                                            />
                                        </li>
                                        <li>
                                            <a id="my-anchor-element2"> <img src="/images/elle-logo-1.png" /></a>
                                            <Tooltip
                                                anchorSelect="#my-anchor-element2"
                                                content="Elle Magazine"
                                            />
                                        </li>
                                    </ul>
                                </div>
                                <h6>* These statement have not been evaluated by the Food and Drug Administration. This product is not intended to diagnose, treat, cure or prevent any disease.
                                    {/* Products on this site contains a value of 0.3% THC or less. */}
                                </h6>
                            </div>
                        </div>
                        <div className="col-md-2">
                            <div className="shop-nav">
                                <h4>About Us</h4>
                                <ul style={{ marginBottom: '6px' }}>
                                    <li><a href={`/${storeAssigned()}/about_us#ourStory`}>Our Story</a></li>
                                    <li><a href={`/${storeAssigned()}/about_us#ourTeam`}>Our Team</a></li>
                                    <li><a href={`/${storeAssigned()}/contact_us`}>Contact Us</a></li>
                                    <li><a href={`/${storeAssigned()}#review`}>Reviews</a></li>
                                </ul>
                                <div className="contact_phonenumber common_css">
                                    <ul>
                                        <li><a href="/pdf/ABEYTU_PRIVACY_POLICY.pdf" target='_blank'>Privacy Policy</a></li>
                                    </ul>
                                </div>
                                {/*<ul>
                                
                                    <li><a href="#">Micronutrient Infused Gummies</a></li>
                                    <li><a href="#">Well Immunity Booster</a></li>
                                    <li><a href="#">Wellness Essentials</a></li>
                                    <li><a href="#">Skin Relief Cream</a></li>
                                    <li><a href="#">Rejuvenation Vitamin C Cream</a></li>
                                    <li><a href="#">Hand, Foot, and Nail Restore Cream</a></li>
                                    <li><a href="#">Not Just For Teens Rescue Cream</a></li>
                                    <li><a href="#">3xDaily Maintenance Balm + 2XLysine Balm</a></li>
                                    <li><a href="#">Lips Kit</a></li>
    </ul>*/}
                            </div>
                        </div>
                        {/* <div className="col-md-2">
                            <div className="help-nav">
                                <h4>Join Us</h4>
                                <ul>
                                    {storeAssigned() !== 'se' && <li><a href={`/${storeAssigned()}/enrollment#affiliate`}>Distributor</a></li>}
                                    {storeAssigned() !== 'se' && <li><a href={`/${storeAssigned()}/enrollment#wholesale`}>Wholesale</a></li>}
                                    <li><a href={`/${storeAssigned()}/enrollment#subscription`}>Subscriptions</a></li>
                                    <li><a href={`/${storeAssigned()}/enrollment#loyalty`}>Abeytu´&reg; Loyalty Program</a></li>
                                    <li><a href="/pdf/ShamanCompensationPlan.pdf" target="_blank">Compensation Plan</a></li>

                                </ul>
                            </div>
                        </div> */}
                        <div className="col-md-2">
                            <div className="help-nav">
                                <h4>Quick Links</h4>
                                <ul>
                                    <li><a href={`/${storeAssigned()}/about_us#FAQs`}>FAQ</a></li>
                                    <li><a href={`/${storeAssigned()}/about_us#COA`}>COA </a></li>

                                </ul>
                            </div>
                        </div>
                        <div className="col-md-2">
                            <div className="privacy-policy-nav">
                                <h4>Privacy & Terms</h4>
                                <ul>
                                    {/* <li><a href="#">Accessibility Policy</a></li> */}
                                    <li><a href="/pdf/ABEYTU_PRIVACY_POLICY.pdf" target='_blank'>Privacy Policy</a></li>
                                    <li><a href={`/${storeAssigned()}/terms-and-conditions`}>Terms & Conditions</a></li>
                                    <li><a href={`/${storeAssigned()}/shipping`}>Shipping</a></li>
                                </ul>
                                <ul className="social-icon-footer">
                                    <li><a href="https://www.facebook.com/abeytunaturals/" target="_blank"><img src="/images/facebookcircle.png" /></a></li>
                                    <li><a href="https://www.instagram.com/abeytu_naturals/" target="_blank"><img src="/images/instagramcircle.png" /></a></li>
                                    <li><a href="https://www.pinterest.com/abeytunaturals/" target="_blank"><img src="/images/pinterestcircle.png" /></a></li>
                                </ul>
                                <ul className="credit-card1">
                                    <li><a href="#"><img src="/images/Visa2.jpg" /></a></li>
                                    <li><a href="#"><img src="/images/MasterCard.jpg" /></a></li>
                                    <li><a href="#"><img src="/images/Amex.jpg" /></a></li>
                                    <li><a href="#"><img src="/images/2560px-Discover_Card_logo.jpg" /></a></li>
                                </ul>
                                {/* <ul className="credit-card2">
                                    <li><a href="#"><img src="/images/Paypal.png" /></a></li>
                                    <li><a href="#"><img src="/images/sezzle_logo.png" /></a></li>
                                </ul> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="copy-right">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="copy-right-text">
                                <h6>© {(new Date().getFullYear())} Abeytu´&reg; Naturals. All Rights Reserved.</h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </FooterStyleCss >
    )
};

