import _ from 'lodash';
import React, { useEffect } from 'react';
import { storeAssigned, _deleteProductFromCart } from '../../../services/Methods/normalMethods';
import ButtonComponent from '../../../inputs/ButtonCompnent';
import { ErrorMessage, Form, Formik, useFormikContext } from 'formik';
import AddressSection from '../../Checkout/components/AddressSection';
import PaymentSection from '../../Checkout/components/PaymentSection';
import { MainCheckOutStyle } from '../../Checkout/styles/MainCheckout.style';
import ShippingSection from '../../Checkout/components/ShippingSection';
import OrderSummery from '../../Checkout/components/OrderSummery';
import { AddressSuggestionDialog, _sameAsShippingCall } from '../../../services/Methods/commonPayload';
import { OuterLoader } from '../../../services/Methods/checkoutPayloads';
import SavedCard from '../../Checkout/components/SavedCard';
import PointCollection from '../../../Components/PointCollection';
import localStorageCall from '../../../services/Methods/localstorage.hook';
import CouponComponent from '../../../Components/CouponComponent';
import { useLocation } from 'react-router-dom';

const SummerySection = (props) => {
    const {
        setCartValues, checkoutObject, paymentFormHtml, paymentsOptions, selectedProduct, _deleteProductFunction, _increseCheckoutQuantity,
        countryCodes } = props;
    const location = useLocation();
    const path = '/us/loyalty_program';
    const {
        subTotal, shippingMethods, selectedCheck, VALIDATION_SCHEMA, shippingAutoshipMethods, handleChangeFunction, countryStateCheckout,
        setCountryStateCheckout, selectedAutoshipCheck, calculatedAutoshipData, setSameShippingMethod, calculatedData,
        error, _onHandleCheckoutSubmit, _handleShippingChange, valuesShip, userData, setUserData,
        errorAddressData, setUseDefaultAddressOrNot, useDefaultAddressOrNot, _handleCloseAddressDialog, normalAddressError, savedThisCard, setSavedThisCard,
        onSelectedCardError,
        selectedOptionForPayment, setSelectedOptionForPayment, selectedPaymentOption, _handleCardChange,
        savedCards, selectedCard, setSelectedCard, usePoint, setUsePoint, couponCode, setCouponCode, _onCouponClick, couponErrorData,
        sameAsBillingChecked, setSameAsBillingChecked, _paymentOptionSetBilling,
    } = checkoutObject;

    const CreateObject = (row) => ({
        id: row?.itemId,
        productName: row?.description,
        image: row?.field1,
        price: row?.itemPrice,
        quantity: row?.quantity,
        itemCode: row?.fullItemCode,

    });
    const PARENTS_PRODUCT_CALCULATE = _.filter(calculatedData?.details, (value) => value?.parentItemId === null);
    const PARENTS_PRODUCT_AUTOSHIP = _.filter(calculatedAutoshipData?.details, (value) => value?.parentItemId === null);

    const NORMAL_PRODUCTS = _.map(PARENTS_PRODUCT_CALCULATE, (row) => {
        const values = CreateObject(row);
        return ({
            ...values,
            autoship: _.find(selectedProduct, { id: row?.itemId })?.autoship ? _.find(selectedProduct, { id: row?.itemId })?.autoship : (row?.itemPrice > 0) ? 'oneTime' : 'member',
            subProducts: _.map(_.filter(calculatedData?.details, (r) => r?.parentItemId === row?.itemId), (v) => CreateObject(v))
        });
    });

    const AUTOSHIP_PRODUCTS = _.map(PARENTS_PRODUCT_AUTOSHIP, (row) => {
        const values = CreateObject(row);
        return ({
            ...values, autoship: "autoship",
            subProducts: _.map(_.filter(calculatedAutoshipData?.details, (r) => r?.parentItemId === row?.itemId), (v) => CreateObject(v))
        })
    });

    const FocusError = () => {
        const { errors, isSubmitting, isValidating } = useFormikContext();

        useEffect(() => {
            if (isSubmitting && !isValidating) {
                let keys = Object.keys(errors);
                if (keys.length > 0) {
                    const selector = `[name=${keys[0]}]`;
                    const errorElement = document.querySelector(selector);
                    if (errorElement) {
                        errorElement.focus();
                    }
                }
            }
        }, [errors, isSubmitting, isValidating]);

        return null;
    };

    return (
        <div className='checkout_summery'>
            <MainCheckOutStyle>
                <div className='checkout'>
                    <div className='container'>
                        <div className='checkout-main'>
                            <div className='checkout-left'>
                                <h2>Customer Information</h2>
                                <div className='form-info'>
                                    <Formik
                                        enableReinitialize
                                        initialValues={{ ...userData, sameShipping: false }}
                                        validationSchema={VALIDATION_SCHEMA}
                                        onSubmit={(values, actions) => {
                                            const { billing_country, billing_state } = countryStateCheckout;
                                            _onHandleCheckoutSubmit({ billing_country, billing_state, ...values })
                                        }}
                                    >
                                        {({ setFieldValue, values, resetForm, setValues, errors, handleChange, handleBlur }) => {
                                            const FieldMethod = { values, errors, handleBlur, handleChange, handleChangeFunction }
                                            return (
                                                <Form>
                                                    <FocusError />
                                                    {/* <div>
                                                        <h3 className='checkout_heading_address'>Billing Address</h3>
                                                        <AddressSection section="billing" {...{ countryCodes, normalAddressError, valuesShip, countryState: countryStateCheckout, setCountryState: setCountryStateCheckout, ...FieldMethod }} />
                                                    </div> */}

                                                    <div className='shipping-form'>
                                                        {/* <div className='express-shipping ship-address'>
                                                            <input
                                                                type="checkbox"
                                                                name="sameShipping"
                                                                onChange={(e) => {
                                                                    _sameAsShippingCall(resetForm, countryStateCheckout, setCountryStateCheckout, userData, setUserData, values, handleChangeFunction, e)
                                                                }}
                                                            /> Same As Billing Address
                                                        </div> */}
                                                        <div className=''>
                                                            <h3 className='checkout_heading_address'>Shipping Address</h3>
                                                            <AddressSection section="shipping" {...{ countryCodes, normalAddressError, valuesShip, countryState: countryStateCheckout, setCountryState: setCountryStateCheckout, ...FieldMethod }} />
                                                            {error && <p style={{ color: "red" }}>{error}</p>}
                                                        </div>
                                                        <div className='mb-2 field-class neweletter-subscrib'>
                                                            <input type="checkbox" /> Subscribe to our newsletter
                                                        </div>
                                                        <div className='mb-2 field-class'>
                                                            <textarea type="text" placeholder='Order Notes (optional)' />
                                                        </div>
                                                        <div className='shipping_section' style={{ display: "flex", gap: "30px" }}>
                                                            {shippingMethods?.length > 0 &&
                                                                <ShippingSection section="Today's order"  {...{
                                                                    selectedCheck, shippingMethods, _handleShippingChange
                                                                }} />}
                                                            {shippingAutoshipMethods?.length > 0 &&
                                                                <ShippingSection section="autoship" {...{
                                                                    selectedCheck: selectedAutoshipCheck, shippingMethods: shippingAutoshipMethods, _handleShippingChange
                                                                }} />}
                                                        </div>
                                                        {(calculatedData?.pointAccounts?.length > 0) &&
                                                            <PointCollection {...{ calculatedData, usePoint, setUsePoint }} />
                                                        }
                                                        {/* {location?.pathname !== path && <CouponComponent {...{ couponCode, setCouponCode, _onCouponClick, couponErrorData }} />} */}
                                                        
                                                        <div>
                                                            <h3 className='checkout_heading_address'>Payment Info</h3>
                                                            <SavedCard {...{
                                                                setSameAsBillingChecked,
                                                                selectedOptionForPayment, setSelectedOptionForPayment, selectedPaymentOption,
                                                                paymentsOptions, savedCards, _handleCardChange, selectedCard, setSelectedCard
                                                            }} />
                                                            {(selectedOptionForPayment === 2) &&
                                                                <>
                                                                    {(selectedPaymentOption === 1) &&
                                                                        <div className="mt-4 d-flex sameAsShipping-checkbox field-class neweletter-subscrib ">
                                                                            <input
                                                                                type="checkbox"
                                                                                className=''
                                                                                style={{ top: '-2px' }}
                                                                                name="sameAsBilling"
                                                                                checked={sameAsBillingChecked}
                                                                                onChange={(e) => {
                                                                                    if (e?.target?.checked) {
                                                                                        setSameAsBillingChecked(e?.target?.checked);
                                                                                        _paymentOptionSetBilling(userData);

                                                                                    } else {
                                                                                        setSameAsBillingChecked(e?.target?.checked);
                                                                                        window.paymentOption?.clearBilling();
                                                                                    }
                                                                                }} />
                                                                            <p className='' style={{ fontSize: '20px', margin: 0 }}>Same as shipping</p>
                                                                        </div>
                                                                    }
                                                                    <PaymentSection {...{ minHeight: "770px", paymentFormHtml, savedThisCard, setSavedThisCard }} />
                                                                </>
                                                            }
                                                        </div>
                                                        <div>
                                                            <div className="mb-3 field-class shipping condition-checkout">
                                                                <input type="checkbox"
                                                                    checked={values?.termscondition}
                                                                    onChange={(e) => setFieldValue('termscondition', e.target.checked)}
                                                                    id="Terms-Condition"
                                                                    name="termscondition"

                                                                /><p style={{ fontSize: "24px" }}>I have read and agree to the
                                                                    <a style={{ color: "rgb(42, 83, 63)", }} href={`/${storeAssigned()}/terms-and-conditions`}
                                                                        target="_blank"> website terms and conditions*</a></p>
                                                            </div>
                                                            <ErrorMessage name='termscondition' render={(msg) => <p className='error'>{msg}</p>} />
                                                        </div>
                                                        <div className="mb-3 field-class shipping promotions-checkbox">
                                                            <input type="checkbox" id="Terms-Condition1" name="termscondition1" />
                                                            I want to receive updates about products and promotions.
                                                        </div>
                                                        <div className='place-order'>
                                                            <ButtonComponent type='submit' disabled={(NORMAL_PRODUCTS?.length === 0 && AUTOSHIP_PRODUCTS?.length === 0)}>Place Order</ButtonComponent>
                                                        </div>
                                                        {error && <p style={{ color: "red" }}>{error}</p>}
                                                        {onSelectedCardError && <p>{onSelectedCardError}</p>}
                                                    </div>
                                                </Form>
                                            )
                                        }}
                                    </Formik>
                                    {localStorageCall().getSingleItem('error-payment') && <p className='error'>{localStorageCall().getSingleItem('error-payment')}</p>}
                                </div>
                            </div>
                            <div className='mainorder_summery'>
                                {(NORMAL_PRODUCTS?.length > 0)
                                    ?
                                    <OrderSummery
                                        _increseCheckoutQuantity={_increseCheckoutQuantity}
                                        _deleteProductFunction={_deleteProductFunction}
                                        calculate={calculatedData}
                                        cartData={NORMAL_PRODUCTS}
                                        section="Today's"
                                        setCartValues={setCartValues}
                                        subTotal={subTotal?.normalSubTotal} />
                                    :
                                    (AUTOSHIP_PRODUCTS?.length > 0) ? <></> : <OuterLoader section="Normal" />
                                }

                                {(AUTOSHIP_PRODUCTS?.length > 0)
                                    ?
                                    <OrderSummery
                                        _increseCheckoutQuantity={_increseCheckoutQuantity}
                                        _deleteProductFunction={_deleteProductFunction}
                                        section="Autoship"
                                        calculate={calculatedAutoshipData}
                                        cartData={AUTOSHIP_PRODUCTS}
                                        setCartValues={setCartValues}
                                        subTotal={subTotal?.autoshipSubTotal} />
                                    :
                                    (NORMAL_PRODUCTS?.length > 0) ? <></> : <OuterLoader section="Autoship" />
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </MainCheckOutStyle>

            <AddressSuggestionDialog {...{
                errorAddressData, useDefaultAddressOrNot, closeFunction: () => _handleCloseAddressDialog(),
                setUseDefaultAddressOrNot, userData, normalAddressError
            }} />
        </div>
    )

}

export default SummerySection;