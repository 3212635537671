import HomeFirstSection from './components/HomeFirstSection';
import useHomePageHook from './components/HomePage.hook';
import HomePageStyleCss from './style/HomePage.style';
import HomeSecondSection from './components/HomeSecondSection';
import HomeThirdSection from './components/HomeThirdSection';
import { useLocation, useOutletContext } from 'react-router-dom';
import withRouter from '../../services/HOC/withRouterHOC';
import { useEffect,useState } from 'react';
import DialogComponent from '../../inputs/DailogComponent';
import UkImage from "./assets/uk-flag-new.jpg"


const HomePage = (props) => {
    const { navigate } = useOutletContext();
    const location = useLocation();

    useEffect(() => {
        if (location?.hash) {
            document.getElementById(location?.hash)?.scrollIntoView();
        }
    }, [location?.hash])

    const { ProductsDetail } = useOutletContext();
    const {
        loading,
        CUSTOMER_RATING,
        sliderRef,
        next,
        previous,
        creamData,
        topSellers,
        productNext,
        productPrevious,
        productSliderRef,
        _sendGridContactForm, successMesssage
    } = useHomePageHook({ ...props });

    const[openPopup,setPopupOpen]=useState(false);


    return (
        <HomePageStyleCss>
            <HomeFirstSection  {...{ topSellers,ProductsDetail, productSliderRef, productNext, productPrevious, ...props }} />
            <HomeSecondSection {...{ creamData, CUSTOMER_RATING, sliderRef, next, previous, navigate, scrollId: location?.hash, ...props }} />
            <HomeThirdSection {...{ loading, successMesssage, _sendGridContactForm, ...props }} />

            <DialogComponent opend={openPopup} handleClose={() => setPopupOpen(false)} classFor="coutry-display-popup">
            <div className="country-main-popup">
                <div className="left-side-image">
                    <img src={UkImage}/>
                </div>
                <div className="righ-side-content">
                    <h1>Visiting form the UK....?</h1>
                    <p>Find our products in the UK on <a href="#">Na'vi Organics</a></p>
                    <button className="country-navi-button">Go to Na'vi Orangics</button>

                </div>

            </div>

       </DialogComponent>
        </HomePageStyleCss>

        
    )
}

export default withRouter(HomePage);