import styled from "styled-components";
import HomeFirstSection from "../components/HomeFirstSection";
const banner2 = require('../assets/banner2.jpg');
const charity = require('../assets/Charity.jpg')
const HomePageStyleCss = styled.div`
    
`;

export const HomeFirstSectionCss = styled.div`

    .banner-section {
        background: ${`url(${require('../assets/homepageNaturebackground.jpg')})`};
        background-size: cover;
        height: 1000px;
        position: relative;
        background-position: center  top 0;
        background-repeat: no-repeat;
        @media(max-width:1800px) {
           background: url("/static/media/homepageNaturebackground.b1918e1d636771bd213c.jpg") center top -80px / cover no-repeat;
            
        }
        @media(max-width:1680px){ 
            height: 920px;
        }
        @media(max-width:1280px){ 
            height: 820px;
        }
        @media(max-width:767px){ 
            height: 710px;
        }
        
       
    }
    .banner-prd1 {
    position: absolute;
    left: 0;
    right: 0;
    text-align: center;
    bottom: 35px;
    pointer-events: none;
    img{
        max-width:500px
    }
    @media (max-width:1680px){
        bottom:162px;
    }
            @media (max-width:1680px){
                & img {
    max-width: 330px;
}
            }
            @media (max-width:1439px){
                & img {
    max-width: 295px;
}
            }
    
        @media (max-width:1280px){
            & img {
    max-width: 290px;
    width: 100%;
}

        }
        @media (max-width:991px){
            & img {
    max-width: 200px;
}
        }
        @media (max-width:767px){
            & img {
    max-width: 170px;
}
        }
    
}
.banner-logo {
    position: absolute;
    bottom: 62px;
    left: 50px;
    @media (max-width:767px){
        bottom: 75px;
    left: 2px;
    }
}
.banner-logo img {
    max-width: 200px;
    width: 100%;
    @media (max-width:767px){
        max-width: 150px;
    }
}
    .banner-text h1 {
        color: #FFFFFF;
        text-shadow: 0px 4px 20px #000000CC;
        font-size: 50px;
        font-family: 'Playfair Display';
        font-weight: bold;
        text-align: center;
        max-width: 1310px;
        @media (max-width:1500px){
                font-size: 46px;
                max-width: 100%;
                width:100%;
        }
        @media (max-width:1365px){
                font-size: 35px; 
        }
        @media (max-width:767px){
                font-size: 30px;  
        }
        @media (max-width:568px){
                font-size: 23px;  
        }
    }
    .banner-text {
        padding: 173px 79px 0;
        @media (max-width:767px){
            padding: 130px 0 0; 
        }
        @media (max-width:568px){
            padding: 90px 0 0; 
        }
    }
    
    .started-btn {
        background: 0% 0% no-repeat padding-box padding-box rgb(57, 51, 46);
    border-radius: 50px;
    font-size: 24px;
    max-width: 214px;
    height: 57px;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    color: rgb(255, 255, 255);
    position: relative;
    right: 120px;
    width: 100%;
    float: right;
    font-weight:bold;
    bottom: -102px;
    @media (max-width:1439px){
    right: 0;
    }
    @media (max-width:991px){
        float: none;
        bottom: -10px;
        margin: 0 auto;
    }

        a {
            color: #fff;
            font-size: 24px;
            font-weight: bold;
            @media (max-width:568px){
                font-size:20px;
                }
        }
        &:hover {
            background: #FFF;
            color: #39332E;
            a {
                color: #39332E;
            }
        }
    }
    .best-seller-text {
        text-align: center;
        padding: 72px 0px 60px;
        @media(max-width:1199px){
            padding:0;
        }
        h2 {
            color: #2A533F;
        }
        h3 {
            font-size: 30px;
            padding: 25px 0px 67px;
            @media(max-width:568px){
                font-size: 18px !important;
                    padding: 0 0px 10px;
                    line-height: 22px;
                }
        } 
        }
        .prd1 {
            height: 340px;
             padding: 20px 0;
            @media(max-width:767px){
                height:290px;
            }
            & img {
                max-width: 299px;
                margin: 0 auto;
                width:100%;
                height: 100%;
                object-fit: contain;
                @media(max-width:767px){
                   max-width: 210px;

                }
            }
        }
        .item {
            overflow: hidden;
            width: 100%;
            max-width: 442px;
            margin: 0 auto;
            @media(max-width:1439px){
                background: rgb(255, 255, 255);
                box-shadow: rgba(0, 0, 0, 0.1) 0px 6px 12px;
            }
            @media(max-width:1199px){
           margin-bottom:30px;
            }
            @media(max-width:991px){
                box-shadow: rgba(0, 0, 0, 0.1) 0px 0 12px;
            }

            h3 {
                color: #39332E;
                font-size: 24px;
                text-align: left;
                font-family: 'Playfair Display';
                font-weight: bold;
                padding: 0;
                text-align: center;
            }
            p {
                letter-spacing: 0.09px;
                font-size: 16px;
                text-align: center;
                line-height: 20px;
            }
        }
        .owl-dots {
            display: none;
        }
        .owl-nav {
            width:100%;
        }
        .owl-item {
            padding: 40px 0 80px;
        }
        div#best-seller > button.MuiButtonBase-root.MuiIconButton-root:nth-child(1) {
            background: transparent;
            left: -110px;
            border: 1px solid #E8E8E8;
            border-radius: 10px;
            width: 69px;
            height: 69px;
            @media(max-width:1500px){
                left: -70px;
            }
            @media(max-width:1439px){
                left: -50px;
            }
            @media(max-width:1199px){
                left: 0;
                z-index: 9;
                background: #fff;
            }
            @media(max-width:767px){
 
                    width: 50px;
                    height: 50px;
                }
        }
        div#best-seller {
          position: relative;
        }
        div#best-seller svg {
          font-size: 42px;
           color: #5555559e;
             }
             div#best-seller button {
                position: absolute;
                top: 32%;
            }
          #best-seller > button.MuiButtonBase-root.MuiIconButton-root:nth-child(3) {
                background: transparent;
                right: -110px;
                border: 1px solid rgb(232, 232, 232);
                border-radius: 10px;
                width: 69px;
                height: 69px;
                @media(max-width:1500px){
                    right: -70px;
                }
                @media(max-width:1439px){
                right: -50px;
            }
            @media(max-width:1199px){
                right: 0;
                z-index: 9;
                background: #fff;
            }
            @media(max-width:767px){
                width: 50px;
                height: 50px;
                }
            
            #best-seller .slick-track {
    padding: 0 0 80px;
    @media(max-width:767px){
        padding: 0 0 20px;
                }
            }

        }
    .prd-text {
        padding: 0 15px;
        @media(max-width:568px){
            padding: 0 10px;
             width:100%;
        }

        p {
            display: -webkit-box;
            text-overflow: ellipsis;
            overflow: hidden;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 4;
        }

    }
 
    .best-seller-section>.container {
        max-width: 1163px;
        width:100%;
        @media(max-width:1439px){
            max-width: 1140px;
        }
        @media(max-width:1280px){
            max-width: 1000px;
        }
    }
    .price h3 {
        color: #39332E;
        font-family: 'Helvetica Neue LT Std' !important;
        font-weight: 600;
        font-size:21px;
        @media(max-width:1439px){
            font-size:16px;
        }
        
    }
    .start-price {
        display: flex; 
        justify-content: center;
        flex-wrap: wrap;
        width: 100%;
         @media(max-width:568px){
            flex-wrap: wrap;
            justify-content: center;
        }
        .price {
            max-width:100%;
            flex:0 0 100%;
            @media(max-width:568px){
            width: 100%;
            }
            h3{
            @media(max-width:568px){
            
                 text-align: center;
                 font-size:18px ;
        }
        }
        }

    }
    .start-price .show-all {
        max-width: 130px;
        width: 100%;
        margin: 4px 0 20px 0;
        @media(max-width:568px){
    margin: 10px 0;
        }
    }
    .start-price .show-all a.btn.btn-show:hover , .creams-text a.btn.btn-show:hover , .btn.btn-show:hover , .btn-all:hover {
        border: 2px solid #2a533f;
        color: #2a533f;
        background: transparent;
    }
    .owl-nav span {
        font-size: 0;
    }
    .best-seller-section button.owl-prev , .best-seller-section button.owl-next{
        position:relative;
    } 
    .best-seller-section button.owl-prev::after {
        position: absolute;
        content: "\f104";
        font-family: 'FontAwesome';
        font-size: 41px;
        color: #555;
        height: 61px;
        width: 61px;
        border: 1px solid #E8E8E8;
        border-radius: 10px;
        left:0;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .best-seller-section button.owl-next::after {
        position: absolute;
        content: "\f104";
        font-family: 'FontAwesome';
        font-size: 41px;
        color: #555;
        height: 61px;
        width: 61px;
        border: 1px solid #E8E8E8;
        border-radius: 10px;
        right: 0;
        transform: rotate(180deg);
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .best-seller-section .owl-theme .owl-nav button {
        width: 50px;
        height: 50px;
        position: absolute;
        top: 36%;
    }
    .best-seller-section .owl-theme .owl-nav button.owl-prev {
        left: -90px;
    }
    .best-seller-section .owl-theme .owl-nav button.owl-next {
        right: -90px;
    }
    .best-seller-section .owl-theme .owl-nav [class*=owl-]:hover {
        background:transparent;
    }
    div#best-seller {
    display: flex;
    flex-wrap: nowrap;
    gap: 24px;
    justify-content: center;
    @media(max-width:1439px){
        flex-wrap: wrap;
        }

    .item h3{
        height: 74px;
    }
    .start-price h3 {
	height: auto !important;
}
}
    .we-unique-section .container {
    max-width: 1200px;
    margin: 0 auto;
    @media(max-width:1439px){
            max-width: 1140px;
        }
        @media(max-width:1280px){
            max-width: 1000px;
        }
}
    .unique-text h2 {
        color: #2A533F;
        padding-bottom: 20px;
    }
    .unique-text {
        text-align: center;
        padding: 0 0 20px;
    }
    .unique-logo {
        display: flex;
        margin: 50px 0;
        @media(max-width:1280px){
            flex-wrap: wrap;
    justify-content: center;
        }
        @media(max-width:991px){
       margin:20px 0;
        }
    }
    .logo-unique {
        max-width: 20%;
        flex: 0 0 20%;
        @media(max-width:1280px){
            max-width: 33%;
            flex: 0 0 33%;
            margin-bottom: 30px;
        }
        @media(max-width:568px){
            max-width: 48%;
            flex: 0 0 48%;
            margin-bottom: 20px;
    
        }
        img {
            width: 100%;
            max-width: 179px;
            @media(max-width:1199px){
                width: 100%;
                max-width: 100px;
                height: 100px;
                object-fit: cover;
            }
        }
        p {
            letter-spacing: 0.07px;
            padding: 20px 0;
            max-width: 187px;
            margin: 0 auto;
            width: 100%;
            line-height: 20px;
        }
    }
    
`;

export const HomeSecondSectionCss = styled.div`
    .top-rated-section {
        background: #F4F0EB;
    }
    .product1 {
        box-shadow: 0px 8px 120px #00000026;
        border-radius: 10px;
        background: #fff;
        text-align: center;
        padding: 20px 20px 90px;
        position: relative;
        max-width: 330px;
        flex: 0 0 330px;
        @media(max-width:1365px){
            max-width: 250px;
        flex: 0 0 250px;
        }
        @media(max-width:1199px){
            max-width: 30%;
           flex: 0 0 30%;
        }
        
        @media(max-width:991px){
            max-width:48%;
           flex: 0 0 48%;
        }
        @media(max-width:568px){
            max-width:100%;
           flex: 0 0 100%;
        }
    }
    .product1 p {
        line-height: 16px;
        padding: 10px 0 0;
        max-width: 340px;
        width: 100%;
        margin: 0 auto;
        @media(max-width:568px){
            max-width: 100%;
        }
    }
    .product1 h3 {
        color: #39332E;
        font-family: 'Playfair Display';
        max-width: 334px;
        margin: 0 auto;
        font-weight: bold;
        line-height: 28px;
        width: 100%;
        padding: 28px 0 0;
    }
    
    .top-rated-text h2 {
        text-align: center;
        padding: 54px 0 70px;
        @media(max-width:767px){
            padding: 54px 0 30px;
        }
        @media(max-width:568px){
            padding: 30px 0;
        }
    }
    .creams-text a.btn.btn-show {
        text-align: center;
        margin: 0 auto;
        display: flex;
        max-width: 147px;
        width: 100%;
    }
    .top-rated-section {
        background: #F4F0EB;
        margin-bottom: 290px;
        padding-bottom: 290px;
        @media(max-width:1199px){
            margin-bottom: 0;
    padding-bottom: 69px;
        }

        @media(max-width:767px){
            margin-bottom:0;
        padding-bottom: 80px;
        }
        @media(max-width:568px){
        padding-bottom: 35px;
        }

    }
    .creams-text .show-all {
        position: absolute;
        left: 0;
        right: 0;
        margin: 0 auto;
        bottom: 32px;
    }
    .creams-text {
        display: flex;
        gap: 85px;
        position: absolute;
        left: 0;
        right: 0;
        margin: 0 auto;
        justify-content: center;
        @media(max-width:1439px){
            gap:40px;
        }
        @media(max-width:1199px){
            gap:15px;
            position: relative;
    flex-wrap: wrap;
        }
        @media(max-width:767px){
            position: relative;
           flex-wrap: wrap;
        }

    }
    .creams-text a.btn.btn-show {
    text-align: center;
    margin: 0 auto;
    display: flex;
    max-width: 147px;
    width: 100%;
    justify-content: center;
    color: #fff;
}
.creams-text img {
    @media(max-width:767px){
    max-width: 161px;
    object-fit: cover;
}
}
    /* testinomal */
    .client-section h3 {
        letter-spacing: 2px;
        font-size: 20px;
        line-height: 70px;
        font-weight: bold;
        color: rgb(42,83,63);
        font-family: 'Helvetica Neue LT Std';
        @media(max-width:767px){
            text-align: left;
            line-height: normal;
        }
        @media(max-width:568px){
            font-size: 16px;
    text-align: left;
        }
    }
    .client-section {
        padding: 80px 0;
        @media(max-width:1439px){
    padding: 24px 0px;
        }
         @media(max-width:1199px){
            padding:30px;
         }
         @media(max-width:767px){
            padding:20px 0;
         }
    }
    .client-text h2 {
        font-family: 'Helvetica Neue LT Std';
        @media(max-width:767px){
            text-align: center;
        }
        @media(max-width:767px){
            text-align: left;
    font-size: 18px;
        }
    }
    a.btn.btn-all.btn-review {
        background-color: #39332E;
        height: 45.5px;
        max-width: 230px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        font-size: 18px;
    }
    .write-review {
        margin:20px 0px 0px;
    display: flex;
    justify-content: space-between;
    @media(max-width:1199px){
        margin: 35px 0px 0px;
    }
    }
    .star-review ul {
        display: flex;
    }
    .star-review ul li {
    list-style:none;
    }
    .star-review ul li i {
        color: #F4BC61;
    }
    .star-review ul li {
        padding: 0 5px;
    }
    .client-section .item {
        max-width: 460px;
        width: 100%;
        padding:40px;
        @media(max-width:1366px){
            max-width:100%;
            padding:15px;
        }
    }
    .client-review h6 {
        font-size: 16px;
        color: #555555;
        line-height: 22px;
        text-align: center;
        @media(max-width:992px){
            font-size: 15px;
        }
        @media(max-width:568px){
            font-size: 13px;
            line-height: 20px;
        }
    }
    .client-review {
        background: #FFFFFF;
        box-shadow: 0px 4px 24px #0000001A;
        border-radius: 10px;
        padding: 20px;
        margin:20px 0;
        /* height: 460px; */
        position: relative;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-content: center;     
        @media(max-width:1365px){
            height: 540px;
        }
        @media(max-width:1199px){
            padding:15px;
        }
        @media(max-width:992px){
            height: 450px;
        }
        @media(max-width:767px){
            height: 290px;
        }
        @media(max-width:568px){
            margin:20px 0;
        }
        @media(max-width:480px){
            height: 380px;
        }
    }
    .client-name h3 {
        font-size:18px;
        font-weight: bold;
        color: #000;
        text-align: center;
        line-height:24px;
        text-transform: uppercase;
        padding: 0 10px;
        @media(max-width:767px){
            font-size:16px;
        }
        @media(max-width:767px){
            font-size:14px;
            padding: 0 5px;
        }
    }

    .testimonial-slider {
        max-width: 1357px;
        margin: 0 auto;
        @media(max-width:1680px){
            max-width: 1280px;

    }
    @media(max-width:1365px){
            max-width: 1180px;

    }
    @media(max-width:1279px){
            max-width: 1080px;

    }
    }
    .testimonial-slider .owl-dots {
        display: none;
    }
    #testo .slick-arrow.slick-prev::before {
	position: absolute;
	color: #000;
    content: "\f104";
	font: normal normal normal 40px/1 FontAwesome;
	left: -40px;
	border: 1px solid #ddd;
	width: 50px;
	height: 50px;
	border-radius: 10px;
	line-height: 50px;
    background: #fff;
}
#testo .slick-arrow.slick-prev {
	left: 0 !important;
    @media(max-width:1680px){
	left: 15px  !important;
    z-index: 99;

    }
    @media(max-width:767px){
display:none !important;
    }
}
#testo .slick-slider {
	font-size: 0;
}
#testo  .slick-next {
	right: 0;
    @media(max-width:1680px){
	right: 15px ;
    z-index: 99;

    }
    @media(max-width:767px){
display:none !important;
    }
}
#testo .slick-next::before {
	position: absolute;
	color: #000;
    content: "\f105";
	font: normal normal normal 40px/1 FontAwesome;
	right: -40px;
	border: 1px solid #ddd;
	width: 50px;
	height: 50px;
	border-radius: 10px;
	line-height: 50px;
    background: #fff;
}
.testimonial-slider .slider_values {
	overflow: inherit !important;
}
    .testimonial-slider button.owl-prev::after {
        position: absolute;
        content: "\f104";
        font-family: 'FontAwesome';
        font-size: 41px;
        color: #555;
        height: 61px;
        width: 61px;
        border: 1px solid #E8E8E8;
        border-radius: 10px;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .testimonial-slider button.owl-next::after {
        position: absolute;
        content: "\f104";
        font-family: 'FontAwesome';
        font-size: 41px;
        color: #555;
        height: 61px;
        width: 61px;
        border: 1px solid #E8E8E8;
        border-radius: 10px;
        left: 149px;
        display: flex;
        align-items: center;
        justify-content: center;
        transform: rotate(180deg);
    }
    .testimonial-slider .owl-nav {
        position: absolute;
        top: -15%;
        right:374px;
    }
    button.btn.btn-all.btn-review {
    background: #2A533F;
    max-width: 230px;
    width: 100%;
    color: #fff;
    height: 45px;
    border-radius: 28px;
    font-size: 18px;
    font-weight: bold;
    border:2px solid transparent;
    @media(max-width:568px){
        max-width: 178px;
        font-size: 16px;
    }
}
button.btn.btn-all.btn-review:hover {
    color: #2A533F;
   background: transparent;
   border:2px solid #2A533F;
}
.rating_slider_buttons button:hover {
    background: none;
}
.rating_slider_buttons button {
    border: 1px solid #E8E8E8;
    border-radius: 10px;
    margin-right: 10px;
    width: 61px;
    height: 55px;
    top: 6px;
    @media(max-width:568px){
        width: 40px;
    height: 40px;
    }
}
.rating_slider_buttons svg {
    font-size: 40px;
}
.rating_slider_buttons button.MuiButtonBase-root.MuiIconButton-root:first-child {
    left:104px;
    @media(max-width:1900px){
            left:-20px;
    }
}
.rating_slider_buttons button.MuiButtonBase-root.MuiIconButton-root:nth-child(2) {
    right: -144px;
    @media(max-width:1900px){
            right: 0;
    }
}

    .ingredients-section {

        position: relative;
    z-index: 1;
    overflow: hidden;
    .ingredients-text{
        height: 1075px;
        @media(max-width:1439px){
    height: 720px;
           }
           @media(max-width:991px){
    height: 530px;
           }
           @media(max-width:600px){
    height: 450px;
           }
           @media(max-width:500px){
            height:325px;
           }
           @media(max-width:420px){
            height:300px;
           }
           @media(max-width:390px){
            height:250px;
           }

       img {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
   
    /* animation-name: scale-out-top;
		animation-duration: 5s;
		animation-timing-function: ease;
		animation-delay: 0s;
		animation-iteration-count: 1;
		animation-direction: normal;
		animation-fill-mode: forwards; */
        animation: fadeOut 20s;
        animation-timing-function: ease;
		animation-iteration-count: 1;
		animation-direction: normal;
        animation-fill-mode: forwards;
			
		/* shorthand
		animation: scale-out-top 1.2s ease 0s 1 normal forwards;*/
}
    iframe {
            width: 100%;
           height: 1075px;
           @media(max-width:1439px){
    height: 720px;

           }
           @media(max-width:991px){
    height: 530px;
           }
           @media(max-width:600px){
    height: 450px;
           }
           @media(max-width:500px){
            height:325px;
           }
           @media(max-width:420px){
            height:300px;
           }
           @media(max-width:390px){
            height:250px;
           }

        }


    }
    .ingredients-text-now {
    position: absolute;
    z-index: 99999;
    top: 40%;
    color: #fff;
    left: 19%;
    @media(max-width:991px){
        top: 27%;
    left: 9%;
           }
           @media(max-width:480px){
            top: 58px;
           }
}
}
.ingredients-text-now p {
    color: #fff;
    font-size: 40px;
    font-family: "Playfair Display";
    max-width: 40%;
    @media(max-width:991px){
        font-size: 25px;
           }
           @media(max-width:991px){
        font-size: 25px;
           }
           @media(max-width:568px){
            max-width: 90%;
            font-size: 18px;
           }
}
a.btn.btn-gredients {
    background: #484848;
    color: #fff;
    font-size: 18px;
    font-weight: bold;
    @media(max-width:568px){
            font-size: 14px;
           }
}

    .testimonial-slider .slider_values {
    overflow: hidden;
}
`;

export const HomeThirdSectionCss = styled.div`
    /* Organisation */
    .orgainisation {
        background: ${`url(${charity})`} no-repeat;
        background-size: 100%;
        background-position: top -78px center;
        object-fit: cover;
        padding: 70px 0;
         @media (max-width:1800px){
            background-position: center;
            height: 642px;
         }
         @media (max-width:1500px){
            background-position: bottom;
         }
         @media (max-width:1439px){
            background-position: center;
            height: 100%;
            background-size: cover;
         }
         @media (max-width:767px){
            padding: 20px 0;
            margin:20px 0;
         }
    }
    .community-section {
        background: #F4F0EB;
        padding: 62px 0 56px;
        text-align: center;
        @media(max-width:767px){
            padding: 10px 0px 37px;
            }
    }
    .community-form {
    position: relative;
}
    .organisation-text {
        padding: 0 0 0 40px;
        @media(max-width:1199px){
            padding: 0 ;
        }
        .orgaination-logo img {
            @media(max-width:767px){
                max-width: 208px;
            }
            @media(max-width:568px){
                max-width:160px;
            }
        }
        
        h2 {
            max-width: 672px;
            width: 100%;
            margin: 0 0 40px;
            @media(max-width:767px){
           max-width:100%;
           margin:0 0 7px;
        }
        }
        h3 {
            max-width: 717px;
            margin: 60px 0 30px;
            width: 100%;
            line-height: 30px;
        @media(max-width:1500px){
            max-width: 55%;
            margin: 46px 0px 30px;
                }
                @media(max-width:767px){
           max-width:100%;
           margin: 20px 0;
           color:#fff;
    
        }
            }
    }
    .community-form .btn-all:hover {
    background: transparent;
    color: #2a533f;
    border: 2px solid #2a533f;
}
    .community-text h2 {
        color: #2A533F;
        line-height: 70px;
    }
    .community-text h3 {
        padding: 0 0 20px;
        @media(max-width:767px){
            max-width: 100%;
        flex: 0 0 100%;
        }
    }
    .community-form form {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        width: 100%;
        max-width: 730px;
        margin: 0 auto;
        column-gap: 30px;
        row-gap: 20px;
    }
    .community-form .form-group {
        max-width: 47%;
        flex: 0 0 47%;
        @media(max-width:767px){
            max-width: 100%;
        flex: 0 0 100%;
        }
    }
    .community-text {
        max-width: 1300px;
        margin: 0 auto;
        width:100%;
    }
    .community-form input , .community-form select{
        border-radius: 10px;
        box-shadow: 0px 20px 70px #0000001A;
        border: none;
        background: #fff;
        font-size: 16px;
        font-family: Helvetica Neue LT Std;
        color: #555555;
    }
    .community-form  .form-control:focus {
        border-color: none;
        box-shadow: none;
    }
    .community-form input::placeholder {
        font-family: Helvetica Neue LT Std;
        color: #555555;
        opacity: 0.5;
        font-size: 16px;
    }
    .birhmonth div:first-child {
    width: 100%;
}
    .community-form .birhmonth {
        background: #fff;
        border-radius: 10px;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        box-shadow: 0px 20px 70px #0000001A;
        padding: 0 15px 0 0;
    }
    .community-form label {
    padding: 0 11px;
}
.birhmonth label {
    margin-left: -9px;
}
.community-form .css-1jy569b-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
    color: #000;
}
    .selectBox__value::after {
        position: absolute;
        content: "\f078";
        width: 20px;
        font: normal normal normal 14px/1 FontAwesome;
        height: 20px;
        right: 0;
        top: 1px;
        display: flex;
        align-items: center;
        color: #555555;
    }


    .community-form .drop-list {
        width: 100%;
    }
    .community-form .btn.btn-primary.btn-lg.dropdown-toggle {
        background: transparent;
        border: none;
        font-size: 16px;
        font-family: Helvetica Neue LT Std;
        color: #555555;
        opacity: 0.5;
        position: relative;
        width: 100%;
        text-align: left;
        padding: 0;
    }
    .community-form .dropdown-toggle::after {
        position: absolute;
        right: 0;
        top: 17%;
        content: "\f078";
        font: normal normal normal 14px/1 FontAwesome;
        border: none;
        color: #555555;
        font-size: 18px;
    }
    .btn-all {
        background: #2A533F 0% 0% no-repeat padding-box;
        border-radius: 28px;
        border: none;
        max-width: 214px;
        width: 100%;
        height: 57px;
        font-size: 24px;
        font-weight: bold;
        border:2px solid transparent;
        @media(max-width:568px){
         font-size:16px;
        }
    }
    .community-form button.btn-all {
        margin: 32px 0 0;
    }
    .community-form .dropdown-menu.show {
        display: block;
        width: 100% !important;
    
    }
    body .btn-primary:not(:disabled):not(.disabled).active:focus, body .btn-primary:not(:disabled):not(.disabled):active:focus, body .show>.btn-primary.dropdown-toggle:focus {
        box-shadow:none;
    }
    .btn-primary.focus, .btn-primary:focus{ 
        box-shadow:none;
    }
    .chat-icon {
        position: absolute;
        right: 0;
        color: #2a533f;
        font-size: 67px;
        bottom: -27px;
        @media(max-width:568px){
         font-size:40px;
        }
    }
    .birhmonth fieldset.MuiOutlinedInput-notchedOutline.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
    border: none;
}
.birhmonth .css-1869usk-MuiFormControl-root {
    min-width: 300px;
}
.birhmonth label[data-shrink=false]+.MuiInputBase-formControl .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
    padding-left: 0 !important;
}
.community-form .button_css.undefined {
    margin: 40px 0 0;
}
.community-form button:hover {
    border: 2px solid rgb(42, 83, 63);
    background: transparent;
    color: rgb(42, 83, 63) !important;
}


@media (max-width:1199px){
#best-seller  .item {
    max-width: 48%;
    flex: 0 0 48%;
    margin-bottom: 30px;
}
.we-unique-section {
    margin: 40px 0 0;
}
}
@media (max-width:991px){
.prd1 img {
    max-width: 220px;
}
.prd1 {
    height: 310px;
}
}
@media (max-width:568px){
.slick-dots {
    bottom: -4px !important;
   
}
 .slick-dots li {
    width: 14px!important;
    height: 14px !important;
    margin: 0  1px 3px !important;

}
}

        @keyframes fadeOut {
            0% {opacity: 1;}
            100% {opacity: 0;}
         }

`;


export default HomePageStyleCss;