import { createSlice } from '@reduxjs/toolkit';
import api from '../../AxiosHelper';
import { MIDDLE_CREDENTIAL, storeAssigned } from '../../Methods/normalMethods';

const AllProductSlice = createSlice({
    name: "allProductPage",
    initialState: {
        data: [],
        creamData: [],
        paginationData: [],
        enrollmentPack: [],
        memberShipPack: [],
        topProducts: [],
        topSellers:[],
        totalCount: 0,
        error: '',
        isLoading: false,
        colors: [],
    },
    reducers: {
        setAllProducts: (state, action) => {
            if (action.payload?.section === "enrollmentPack") {
                state['enrollmentPack'] = action.payload?.res;
            } else if (action.payload?.section === "memberShipPack") {
                state['memberShipPack'] = action.payload?.res;
            } else if (action.payload?.section === "creamproduct") {
                state['creamData'] = action.payload?.res
            } else if (action.payload?.section === 'topProduct') {
                state['topProducts'] = action.payload?.res;
            } else if (action.payload?.section === 'topSellers') {
                state['topSellers'] = action.payload?.res;
            } else {
                state['data'] = action.payload?.res;
            }
        },
        setIsLoading: (state, action) => {
            state['isLoading'] = action.payload;
        },
        setPaginationData: (state, action) => {
            state['paginationData'] = action.payload;
            state['totalCount'] = action.payload?.totalRecords
        },

    }
});

export const { isLoading, setIsLoading, setAllProducts, setCreamsProducts, setPaginationData } = AllProductSlice.actions;
export const getAllProductApi = (section = "allProduct", product_id = 1000) => dispatch => {
    dispatch(setIsLoading(true));
    let id = product_id;
    if (section === "enrollmentPack") {
        id = 1001;
    } else if (section === "memberShipPack") {
        id = 1002;
    } else if (section === "creamproduct") {
        id = 1003;
    } else if (section === 'topProduct') {
        id = 1011;
    } else if (section === 'topSellers') {
        id = 1004;
    }

    api().setHeaders(MIDDLE_CREDENTIAL).post().data({
        method: "GET",
        endpoint: `/Crm/Items/WebCategory/${id}?hideFromWeb=eq:false&OrderByColumn=widthMetric&OrderByMethod=desc`
    }).success((res) => {
        if (res?.data) {
            dispatch(setAllProducts({ res, section }));
            dispatch(setIsLoading(false));
        }
    }).error((err) => {
        dispatch(setIsLoading(false));
    }).send()
};

export const getAllProductApiPagination = (currentpage, id = 1000,) => dispatch => {
    dispatch(setIsLoading(true));
    api().setHeaders(MIDDLE_CREDENTIAL).post().data({
        method: "GET",
        endpoint: `/Crm/Items/WebCategory/${id}?hideFromWeb=eq:false&OrderByColumn=widthMetric&OrderByMethod=desc${(storeAssigned() === 'us') ? `&Limit=12&Offset=${currentpage}` : ''}`
    }).success((res) => {
        if (res?.data) {
            dispatch(setPaginationData(res));
            dispatch(setIsLoading(false));
        }
    }).error((err) => {
        dispatch(setIsLoading(false));
    }).send()
};

export default AllProductSlice.reducer;
