import _ from 'lodash';
import React from 'react'
import { useNavigate } from 'react-router-dom';
import LoaderComponent from '../../../Layout/components/Footer/components/Loader';
import classNames from 'classnames';
import ReactpaginateComp from '../../../Components/ReactPaginateComp';
import { storeAssigned } from '../../../services/Methods/normalMethods';

const MainAllProduct = (props) => {
    const { ProductList, isLoading, category, _handleButtonChange, CATEGROY_LIST, offset, totalCount, _onPaginationChange } = props;
    const navigate = useNavigate();
    const ProductIsActive = ProductList?.filter((row) => !row?.IsActive);
    return (
        <div className="Allproduct_section">
            {isLoading && <LoaderComponent />}
            <div className="allProduct_banner-section">
                <figure>
                    <img src={require("../assets/Allproduct_banner_new.jpg")} alt="allproduct page" />

                </figure>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="banner-text">
                                <h1>Our Products</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="main_Allproduct_container">
                            {/* <div className="main_Allproduct_note">
                                <p>NOTE:</p>
                                <p className='note-msg'>
                                    <b>
                                        Overseas customers, please shop with our partner
                                        <i className="fa fa-external-link" aria-hidden="true"></i>
                                        <a href={process.env.REACT_APP_NAVILINK} target="_blank">
                                            NaviOrganics
                                        </a>
                                    </b>
                                </p>
                            </div> */}
                            <h2>Our Products</h2>
                            <div className='allProductText'>
                                <p>Take This Journey With Us and See if Abeytu´&reg; is for You!</p>
                            </div>
                            <div className='toggle_section'>
                                {_.map(CATEGROY_LIST, (row) => (
                                    <button key={row?.hashname} onClick={() => _handleButtonChange(row?.hashname)}
                                        className={classNames('toggle-button', { 'active': category === row?.hashname }, { 'active': (!category && row?.hashname === "products") })}
                                    >
                                        {row?.name}
                                    </button>
                                ))}
                            </div>
                            <div className="product_listing">
                                {ProductIsActive ? _.map(ProductIsActive, ({ salePercentage, points, id, image, para, price, stock }, index) => (
                                    <div className="product_section relative" key={"Allproduct" + index}>
                                        <div className="product_image" >
                                            <img src={image || ""} alt="product" />
                                        </div>
                                        <h3>{para}</h3>

                                        <div className="select_option">
                                            {points && <p>Points Earned = {points}</p>}
                                            <div className="price">
                                                <h4>${price} {stock ? <span className="available">{stock}</span> : ""}</h4>
                                            </div>
                                            <a className='btn btn-show' onClick={() => {
                                                if (!category) {
                                                    navigate(`products/${id}/${_.lowerCase(para)?.replaceAll(/ /g, '_')}`)
                                                } else {
                                                    navigate(`${category}/${id}/${_.lowerCase(para)?.replaceAll(/ /g, '_')}`)
                                                }
                                            }}>Buy Now</a>
                                        </div>
                                        {salePercentage && <div className="cr cr-top cr-right cr-sticky cr-red">{salePercentage} OFF</div>}
                                    </div>
                                ))
                                    :
                                    <div>No Product Found</div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {
                (ProductIsActive?.length > 0) &&

                <ReactpaginateComp currentPage={offset} pageCount={totalCount} handlePageClick={(e) => {
                    _onPaginationChange(e)
                }} />
            }

        </div >
    )
};

export default MainAllProduct;
